import { useState } from "react";
import { useLazyGetOnlineMerchantsQuery } from "src/store";

const useOnlineMerchants = () => {
  const [onlineMerchants, setOnlineMerchants] = useState();
  const [onlineMerchantsIsLoading, setOnlineMerchantsIsLoading] =
    useState<boolean>(false);

  const [getOnlineMerchants] = useLazyGetOnlineMerchantsQuery();

  const fetchOnlineMerchants = async () => {
    try {
      setOnlineMerchantsIsLoading(true);
      const { data, isLoading } = await getOnlineMerchants(undefined);

      const transformedOnlineMerchants = data?.data?.data?.map((merchant) => ({
        id: merchant.id,
        name: merchant.name,
        image: <img src={merchant.image} alt={merchant.name} />,
        branch: merchant?.branches[0]?.branchMSISDN,
      }));

      setOnlineMerchants(transformedOnlineMerchants as any);
      setOnlineMerchantsIsLoading(isLoading);
    } catch (error) {
      console.error("Error fetching online merchants:", error);
    }
  };

  return { onlineMerchants, onlineMerchantsIsLoading, fetchOnlineMerchants };
};

export default useOnlineMerchants;
