import { useNavigate } from "react-router-dom";
import { useGetOfferSectionQuery } from "src/store";
import Card1HomePage from "src/TempPages/HomePageTemp/Card1HomePage";
import Card2HomePage from "src/TempPages/HomePageTemp/Card2HomePage/index";
import DealsSections from "src/TempPages/HomePageTemp/DealsSectionsHomePage";
import HeroHomePage from "src/TempPages/HomePageTemp/HeroHomePage/index";
import { checkAuth } from "src/utility/utility";

const HomePage = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetOfferSectionQuery({
    lang: "ar",
  });

  checkAuth((error as any)?.originalStatus, () => navigate("/login"));

  return (
    <>
      <div>
        <HeroHomePage />
        <div className="grid grid-cols-1 gap-8 py-8 md:grid-cols-2">
          <Card1HomePage />
          <Card2HomePage />
        </div>
        <DealsSections />
      </div>
    </>
  );
};

export default HomePage;
