import type { HTMLAttributes } from "react";

interface ChevronProps extends HTMLAttributes<SVGElement> {}

const Chevron = (props: ChevronProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74309 6.29289C9.35256 6.68342 9.35256 7.31658 9.74309 7.70711L14.036 12L9.74309 16.2929C9.35256 16.6834 9.35256 17.3166 9.74309 17.7071C10.1336 18.0976 10.7668 18.0976 11.1573 17.7071L16.1573 12.7071C16.5478 12.3166 16.5478 11.6834 16.1573 11.2929L11.1573 6.29289C10.7668 5.90237 10.1336 5.90237 9.74309 6.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Chevron;
