import { Card as DsCard } from "@dsquares/react-component-library";
export default function Card({ item }: { item: any }) {
  console.log(item);
  return (
    <a
      target="_blank"
      href="https://www.example.com"
      className="block text-black no-underline"
      rel="noreferrer"
    >
      <DsCard className="border border-solid border-primary shadow-none transition hover:shadow-base">
        <DsCard.Header>
          <img src={item.MerchantImage} />
        </DsCard.Header>
        <DsCard.Title className="text-center">{item.Title}</DsCard.Title>
      </DsCard>
    </a>
  );
}