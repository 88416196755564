import { createSlice } from "@reduxjs/toolkit";
import config from "../../app.config.json";
import { AppConfigState, AppVariant } from "src/models/AppConfigModel.d";
import { hexToRgb } from "src/utility/utility";

export const initialAppConfigState: AppConfigState = {
  colors: {
    primaryColor: config.primary_color,
    secondaryColor: config.secondary_color,
    textColor: config.text_color,
    dangerColor: config.danger_color,
    successColor: config.success_color,
    whiteColor: config.white_color,
    blackColor: config.black_color,
  },
  baseUrl: config.base_api_url,
  variant: config.variant as AppVariant,
  darkLogo: config.darkLogo,
  lightLogo: config.lightLogo,
  favicon: config.favicon,
};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: initialAppConfigState,
  reducers: {
    setAppConfig(state, action) {
      const config = {
        colors: action.payload.colors,
        baseUrl: action.payload.baseUrl,
        variant: action.payload.variant,
        darkLogo: action.payload.darkLogo,
        lightLogo: action.payload.lightLogo,
        favicon: action.payload.favicon,
      };

      return { ...state, ...config };
    },
    reset(state) {
      return initialAppConfigState;
    },
  },
});

export const { setAppConfig, reset } = appConfigSlice.actions;
export default appConfigSlice.reducer;

export const selectAppConfig = (state: { appConfig: AppConfigState }) =>
  state.appConfig;
