import React, { useState, useEffect } from 'react';
import { useLazyAppConfigQuery } from 'src/store/apis/BaseApi';

const withImages = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const [getAppConfig] = useLazyAppConfigQuery();
    const [heroImages, setHeroImages] = useState<{ key: string; src: string }[]>([]);

    async function setImages() {
      const res = await getAppConfig(undefined).unwrap();
      setHeroImages([
        {
          key: "1",
          src: res?.heroImage1,
        },
        {
          key: "2",
          src: res?.heroImage2,
        },
        {
          key: "3",
          src: res?.heroImage3,
        },
      ]);
    }

    useEffect(() => {
      setImages();
    }, []);

    return <WrappedComponent {...props} heroImages={heroImages} />;
  };
};

export default withImages;