import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
export const AUTH_TOKEN_KEY = "auth_token";
export function authInterceptor(headers: Headers) {
  const API_KEY = `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`;
  headers.set("Authorization", API_KEY);
  return headers;
}

export interface ApiResponse {
  result: any;
  message: string;
  statusCode: number;
  statusName: string;
}

export interface ApiValidationErrorResponse {
  data: {
    errors: {
      [key: string]: string[];
    };
  };
}

export const apiErrorMessages: Record<number, string> = {
  400: "The request could not be understood by the server due to malformed syntax",
  401: "You are not authorized to perform this action",
  403: "The request was understood, but it has been refused or access is not allowed",
  404: "The requested resource could not be found",
  500: "Internal server error",
};

export const isApiValidationError = (
  error: unknown
): error is ApiValidationErrorResponse => {
  if (typeof error === "object" && error !== null) {
    const apiError = error as ApiValidationErrorResponse;
    return (
      apiError.data?.errors !== undefined &&
      Object.keys(apiError.data.errors).length > 0
    );
  }
  return false;
};

export const getApiErrorMessage = (error: FetchBaseQueryError) => {
  if (error.status === "FETCH_ERROR")
    return "Cannot connect to the server, Check your internet network";

  if (typeof error.status !== "number") return "Unknown error occurred";

  if (isApiValidationError(error)) {
    const apiErrorsList = Object.values(error.data?.errors);
    return apiErrorsList;
  }

  if (typeof error.data === "object" && error.data !== null) {
    const response = error.data as ApiResponse;
    return response.message;
  }

  return (
    apiErrorMessages[error.status] ||
    "Something went wrong, please try again later"
  );
};
