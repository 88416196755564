import { Pagination } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
interface Props {
  filters: {
    categoryId: number | null;
    pageNumber: number;
    pageSize: number;
  };
  handleFilterChange: Dispatch<
    SetStateAction<{
      categoryId: number | null;
      pageNumber: number;
      pageSize: number;
    }>
  >;
  totalItems: number;
}
export default function Pagenation({
  filters,
  handleFilterChange,
  totalItems,
}: Props) {
  return (
    <Pagination
      total={totalItems}
      current={filters.pageNumber}
      pageSize={filters.pageSize}
      onChange={(page, pageSize) =>
        handleFilterChange({ ...filters, pageNumber: page, pageSize: pageSize })
      }
    />
  );
}
