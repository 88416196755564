import { createSlice } from "@reduxjs/toolkit";
// import config from "../../app.config.json";
import { AppLocalesState } from "src/models/AppLocalesModel";

export const initialAppLocalesState: AppLocalesState = {
  locales: {
    en: [],
    ar: [],
  },
};

const appLocalesSlice = createSlice({
  name: "appLocales",
  initialState: initialAppLocalesState,
  reducers: {
    setAppLocales(state, action) {
      return {
        ...state,
        locales: action.payload.locales,
      };
    },
    resetLocales(state) {
      return initialAppLocalesState;
    },
  },
});

export const selectLocales = (state: { appLocales: AppLocalesState }) =>
  state.appLocales.locales;

export const { setAppLocales, resetLocales } = appLocalesSlice.actions;
export default appLocalesSlice.reducer;
