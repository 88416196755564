export const PARTNERS_ROUTE = "/partners";

export const DealRoutes = [
  {
    id: 11,
    path: `/offers`,
  },
  {
    id: 11,
    path: `/partners`,
  },
  {
    id: 11,
    path: `/vouchers`,
  },
];
