import i18next from "i18next";

export class LanguageService {
  static changeLanguage(langKey: "ar" | "en") {
    i18next
      .changeLanguage(langKey)
      .then(() => LanguageService.changeDocumentLanguageAttributes());
  }
  static switchLanguage() {
    const langKey = i18next.language === "en" ? "ar" : "en";
    LanguageService.changeLanguage(langKey);
  }
  static changeDocumentLanguageAttributes() {
    document.documentElement.dir = i18next.dir();
    document.documentElement.lang = i18next.language;
  }
}
