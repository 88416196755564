import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLazyAppConfigQuery } from "src/store/apis/BaseApi";

export default function Card2HomePage() {
  const [getAppConfig] = useLazyAppConfigQuery();
  const [offerBanner, setOfferBanner] = useState<string>("");
  async function setBanner() {
    const res = await getAppConfig(undefined).unwrap();
    setOfferBanner(res.offerImage);
  }

  useEffect(() => {
    setBanner();
  }, []);

  return (
    <div className="h-full">
      <Link to="/">
        <img
          alt="Ad"
          className="h-64 object-fill rounded-2xl  w-full"
          src={offerBanner}
        />
      </Link>
    </div>
  );
}
