import { SectionedCard, Button, Divider } from "@dsquares/react-component-library"
import Chevron from "src/assets/icons/Chevron";
import GoldIcon from "src/assets/goldLogo.png";
import { useTranslation } from "react-i18next";
import MerchantFlow from "src/components/RedemptionModal/MerchantFlow";
import { useGetWalletDataQuery } from "src/store";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import { useState } from "react";


export default function Card1HomePage() {
  const {t} = useTranslation();
  const { data: walletData, isLoading: walletDataIsLoading, isFetching:
  walletDataIsFetching
   } =
  useGetWalletDataQuery(undefined);

  const [isMerchantFlowModalOpen, setIsMerchantFLowModalOpen] = useState(false);

  
  return(  <> 
  <MerchantFlow
        isOpen={isMerchantFlowModalOpen}
        totalPoints={walletData?.data.totalPoints || 0}
        pointsExpirationDate={dayjs(walletData?.data.expiryDate).format(
          "DD/MM/YYYY"
        )}
        onModalClose={() => {
          setIsMerchantFLowModalOpen(false);
        }}
      />
  <Skeleton active loading={walletDataIsFetching || walletDataIsLoading} >
  <SectionedCard
   className="[&_header]:p-0"
  >
  <SectionedCard.Section
    prefixElement={<img className="size-10" src={GoldIcon} alt="Placeholder" />}
    suffixElement={
      <Button className="text-primary" href="" size="large" variant="link" color="default">
        {t("PointsHistory")}
        <Chevron />
      </Button>
    }
  >
    <SectionedCard.Title>
      {t("Homepage.Balance.Title")}
    </SectionedCard.Title>
    <SectionedCard.Description>
      {t("DetailsPage.description")}
    </SectionedCard.Description>
  </SectionedCard.Section>
  <Divider />
  <SectionedCard.Section
    suffixElement={
      <Button variant="filled" color="primary"
      onClick={() => {
        setIsMerchantFLowModalOpen(true);
      }}
      >
        {t("Homepage.Balance.redeemText")}
      </Button>
    }
  >
    <SectionedCard.Title className="text-16">
    {walletData?.data.totalPoints} {t("DetailsPage.points")}
    </SectionedCard.Title>
    <SectionedCard.Description className="text-16">
      {t("Homepage.Balance.expiryText")} {dayjs(walletData?.data.expiryDate).format("DD MMM YYYY")}
    </SectionedCard.Description>
  </SectionedCard.Section>
</SectionedCard>
</Skeleton>
</>);
}
