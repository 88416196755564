import React from "react";
import ReactDOM from "react-dom/client";
import "src/index.css";
import "src/colors.css";
import App from "src/App";
import { store } from "src/store";
import { Provider } from "react-redux";
import { ZoomLevelProvider } from "src/context/ZoomLevelContext";

import "src/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ZoomLevelProvider>
        <App />
      </ZoomLevelProvider>
    </Provider>
  </React.StrictMode>
);
