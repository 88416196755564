import { RedoOutlined } from "@ant-design/icons";
// import {
//   Button,
//   HistoryPage as DSHistoryPage,
// } from "@dsquares/react-component-library";
import { useCallback, useEffect, useState } from "react";
import { HistoryPage, Button } from "@dsquares/react-component-library";
import {
  useLazyGetAllTransactionsQuery,
  useLazyGetCustomerHistoryQuery,
} from "src/store";
import { useAppTranslation } from "src/hooks/useAppTranslation";
const DSHistoryPage = () => {
  const [pointsPage, setPointsPage] = useState(1);
  const [vouchersPage, setVouchersPage] = useState(1);

  const [voucherData, setVoucherData] = useState<{
    data: any[];
    totalItems: number;
  }>({ data: [], totalItems: 0 });
  const [pointsData, setPointsData] = useState<any[]>([]);

  const [getVouchers] = useLazyGetCustomerHistoryQuery();
  const [getPoints] = useLazyGetAllTransactionsQuery();

  const [isError, setIsError] = useState(false);

  const { i18n, t } = useAppTranslation();

  const voucherColumns = [
    {
      title: t("History.voucherDetails"),
      dataIndex: "voucherDetails",
      key: "voucherDetails",
      width: "8rem",
      align: "start" as const,
    },
    {
      title: t("History.voucher"),
      dataIndex: "voucher",
      key: "voucher",
      width: "8rem",
      render: (text: string) => {
        return text + " %";
      },
    },
    {
      title: t("History.voucherCode"),
      dataIndex: "voucherCode",
      key: "voucherCode",
      width: "8rem",
    },
    {
      title: t("History.expiryDate"),
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: "8rem",
    },
    {
      title: t("History.status"),
      dataIndex: "status",
      key: "status",
      width: "8rem",
      render: (text: string) => {
        return text === "Expired" || text === "منتهى الصلاحية" ? (
          <span className="font-bold">{text}</span>
        ) : (
          <span className="text-success font-bold">{text}</span>
        );
      },
    },
  ];

  const pointsColumns = [
    {
      title: t("History.merchantName"),
      dataIndex: "MerchantName",
      key: "MerchantName",
      width: "8rem",
    },
    {
      title: t("History.transactionId"),
      dataIndex: "TransactionId",
      key: "TransactionId",
      width: "8rem",
    },
    {
      title: t("History.transactionDate"),
      dataIndex: "TransactionDate",
      key: "TransactionDate",
      width: "8rem",
    },
    {
      title: t("History.points"),
      dataIndex: "Points",
      key: "Points",
      width: "8rem",
      render: (text: { points: number; type: number }) => {
        return text?.type === 1 || text?.type === 3 ? (
          <span className="text-danger font-bold">- {text?.points}</span>
        ) : (
          <span className="text-success font-bold">+ {text?.points}</span>
        );
      },
    },
  ];

  const getVouchersData = async (page: number) => {
    const response = await getVouchers({
      page: page,
      pageSize: 10,
      lang: i18n.language,
    });
    if (response.isSuccess) {
      setIsError(false);
      const vouchers = response.data?.Data?.map((item: any) => {
        return {
          key: item?.TransactionId,
          voucherDetails: item?.OfferName,
          voucher: item?.Discount,
          voucherCode: item?.CouponNumber,
          status: item?.Status,
          expiryDate: new Date(item?.ExpiryDate).toLocaleDateString(),
        };
      });
      setVoucherData({
        data: [...voucherData.data, ...vouchers],
        totalItems: response.data?.Count,
      });
      setVouchersPage(page);
    } else if (response.isError) {
      setVoucherData({ ...voucherData, totalItems: voucherData.data.length });
      setIsError(true);
    }
  };

  const getPointsData = async () => {
    const response = await getPoints({
      page: pointsPage,
      pageSize: 10,
      lang: i18n.language,
    });
    if (response.isSuccess) {
      const points = response?.data?.data?.map((item: any) => {
        return {
          key: item?.transactionTime,
          MerchantName: item?.merchantName,
          TransactionId: item?.transactionId,
          TransactionDate: new Date(item?.creationDate).toLocaleDateString(),
          Points: { points: item?.pointsValue, type: item?.logTypeId },
        };
      });
      setPointsData(points);
    }
  };

  useEffect(() => {
    getVouchersData(1);
  }, []);

  useEffect(() => {
    setVoucherData({ data: [], totalItems: 0 });
    getVouchersData(1);
  }, [i18n.language]);

  useEffect(() => {
    getPointsData();
  }, [i18n.language]);

  // return null;
  return (
    <>
      {
        <HistoryPage
        title={t("History.title")}
        tabs={[
          {
            key: "points",
            label: t("History.points").toUpperCase(),
            children: {
              dataSource: pointsData,
              columns: pointsColumns,
              loadData: () => {
                setPointsPage((old) => old + 1);
              },
              totalItems: pointsData.length,
              loading: true,
            },
          },
          {
            key: "voucher",
            label: t("History.vouchers").toUpperCase(),
            children: {
              dataSource: voucherData.data,
              columns: voucherColumns,
              loadData: () => {
                getVouchersData(vouchersPage + 1);
              },
              totalItems: voucherData.totalItems - 1,
              loading: true,
            },
          },
        ]}
        onTabsChange={() => {}}
        onSearchChange={() => {}}
        searchValue=""
        />
      }
      {isError && (
        <div className="w-full flex justify-center items-center gap-2 rounded-e-md p-4">
          <p className="text-danger font-normal mr-2">
            {t("SomethingWentWrong")}
          </p>
          <Button
            className="danger-button"
            icon={<RedoOutlined />}
            onClick={() => getVouchersData(vouchersPage + 1)}
          >
            {t("TryAgain")}
          </Button>
        </div>
      )}
    </>
  );
};

export default DSHistoryPage;
