import { Outlet } from "react-router-dom";

import FooterLayout from "src/TempPages/LayoutTemp/FooterLayout";
import HeaderLayout from "src/TempPages/LayoutTemp/HeaderLayout";

export default function Layout() {
  return (
    <>
      <HeaderLayout />
      <div className="flex flex-col min-h-screen overflow-x-hidden">
        <div className="centered-container flex-1 my-8">
          <Outlet />
        </div>
      </div>
      <FooterLayout />
    </>
  );
}
