import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Listing from "src/components/Listing/Listing";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useGetDealsListPagedQuery } from "src/store";
import Categories from "src/TempPages/VouchersPageTemp/CategoriesVouchersPage";
import Pagenation from "src/TempPages/VouchersPageTemp/PagenationVouchersPage";
import Card from "src/TempPages/VouchersPageTemp/CardVouchersPage";
import { checkAuth } from "src/utility/utility";

const VouchersPage = () => {
  const navigate = useNavigate();

  const { i18n, t } = useAppTranslation("DealsPage");
  const defaultFilters = { categoryId: 0, pageNumber: 1, pageSize: 1 };

  const [filter, setFilter] = useState(defaultFilters);

  const {
    data: offerData,
    error: offerError,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetDealsListPagedQuery(
    {
      evaluateTotalItemsCount: true,
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      categoryId: filter.categoryId as any,
      lang: i18n.language,
      sectionId: 11,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  checkAuth((isError as any)?.originalStatus, () => {
    navigate("/login");
  });

  return (
    <div className="flex flex-col gap-3 items-center">
      <Categories filters={filter} handleFilterChange={setFilter} />
      <Listing
        filters={filter}
        handleFilterChange={setFilter}
        data={offerData}
        handler={{
          isLoading: isLoading,
          isFetching: isFetching,
          isError: isError,
        }}
        Pagenation={Pagenation}
        Card={Card}
      />
    </div>
  );
};

export default VouchersPage;
