/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
export const ellipsis = (text: string, size: number) => {
  return text.length > size ? `${text.slice(0, size)}...` : text;
};

export const keyValArrayToObject = (array: { key: string; value: any }[]) => {
  return array.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {} as { [key: string]: any });
};

export const formatNumber = (number: number) => {
  return number.toLocaleString("en-US");
};

export const compactNumber = (value: number) => {
  return new Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value);
};

export const normalizeBy = <T extends Record<string, any>, R = T>(
  array: T[],
  key: string | number,
  transform?: (item: T) => R
) => {
  return array.reduce((obj, item) => {
    obj[item[key]] = !!transform ? transform(item) : item;
    return obj;
  }, {} as { [key: string | number]: R | T });
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase().concat(word.slice(1));
};

export const checkAuth = (error: number, callback: () => void) => {
  switch (error) {
    case 401:
      localStorage.removeItem("PORTAL_TOKEN");
      callback();
      break;

    default:
      break;
  }
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r} ${g} ${b}`;
};

export const getSessionStorage = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key) || "null");
};
