import { CaretDownOutlined } from "@ant-design/icons";
import { SignupPage } from "@dsquares/react-component-library";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "src/context/ToastContext";
import bgAnimation from "src/assets/video/auth-bg-animation.mp4";
import { useLazyGetGetawayTokenQuery, useLazySendOtpQuery } from "src/store";
import { LanguageService } from "src/services/Language";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

type SignupFormValues = {
  fullname: string;
  mobile: string;
  password: string;
  confirm: string;
};

const Signup = () => {
  const navigate = useNavigate();
  const { i18n, t } = useAppTranslation();
  const notification = useToast();
  const appConfig = useSelector(selectAppConfig);

  const lightLogo = appConfig.lightLogo;
  const appVariant = appConfig.variant;

  const [sendOtp] = useLazySendOtpQuery();
  const [getTokenGetWay] = useLazyGetGetawayTokenQuery();

  const handleSubmit = (formData: SignupFormValues) => {
    let validMobileNumber = formData.mobile.replace("+2", "");

    loginGetWayApi(
      formData.fullname,
      validMobileNumber,
      formData.password,
      formData.confirm
    );
  };

  const loginGetWayApi = async (
    fullName: string,
    mobileNumber: string,
    password: string,
    confirmPassword: string
  ) => {
    const result = await getTokenGetWay(undefined);

    if (result.isSuccess) {
      localStorage.setItem("GETWAY_TOKEN", result.data?.AccessToken);
      rewardApi(fullName, mobileNumber, password, confirmPassword);
      return;
    }
    if (result.isError) {
      if ("data" in result.error) {
        notification.open({
          type: "error",
          content:
            (result.error.data as any).error_description ||
            (result.error.data as any).details,
          duration: 3,
        });
      }
      return;
    }
  };

  const rewardApi = async (
    fullName: string,
    mobileNumber: string,
    password: string,
    confirmPassword: string
  ) => {
    try {
      const res = await sendOtp({
        walletCode: mobileNumber,
        profileOtp: "2",
      });

      if (res?.isSuccess) {
        navigate("/otp", {
          state: { fullName, mobileNumber, password, confirmPassword },
        });
      } else if (res?.isError) {
        if ("data" in res?.error) {
          notification.open({
            type: "error",
            content:
              (res.error.data as any).errorMessage ||
              (res.error.data as any).details,
            duration: 3,
          });
        }
      }
      return;
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  return (
    <div dir={i18n.dir()}>
      <SignupPage
        background={
          <>
            <video
              autoPlay
              className="hidden md:block absolute inset-0 w-full h-full object-cover -z-10 "
              loop
              muted
            >
              <source src={bgAnimation} type="video/mp4" />
            </video>
            <div className="absolute hidden md:block inset-0 bg-primaryDark opacity-80 -z-10" />
          </>
        }
        header={{
          centered: true,
          logo: (
            <a className="flex justify-center" href="https://dsquares.com">
              <img
                alt="Company logo"
                className="hidden md:block w-72 h-10 object-contain ltr:object-left rtl:object-right"
                src={lightLogo}
              />
            </a>
          ),
          languageSwitcherProps: {
            value: i18n.language,
            items: [
              { label: "English", value: "en" },
              { label: "العربية", value: "ar" },
            ],
            handleDirection: (value: "ar" | "en") =>
              LanguageService.changeLanguage(value as "ar" | "en"),
            suffixLanguageIcon: <CaretDownOutlined className={"text-white"} />,
            textColor: "white",
            bordered: false,
          },
        }}
        formSettings={{
          title: (
            <h2 className="text-20 text-28 font-medium">{t("Signup.title")}</h2>
          ),
          footer: (
            <p className="font-medium text-gray-400">
              {t("Signup.alreadyHaveAccount")}{" "}
              <Link className="text-primaryDark" to="/login">
                {t("Signup.login")}
              </Link>
            </p>
          ),
          onSubmit: (e: any) => handleSubmit(e),
          fullNameInputProps: {
            label: t("Signup.fullNameLabel"),
            placeholder: t("Signup.fullNamePlaceholder"),
          },
          mobileInputProps: {
            label: t("Signup.mobileNumberLabel"),
            placeholder: t("Signup.mobileNumberPlaceholder"),
          },
          passwordInputProps: {
            label: t("Signup.passwordLabel"),
            placeholder: t("Signup.passwordPlaceholder"),
          },
          confirmPasswordInputProps: {
            label: t("Signup.confirmPasswordLabel"),
            placeholder: t("Signup.confirmPasswordPlaceholder"),
          },
          submitProps: {
            label: t("Signup.createAccount"),
          },
        }}
        footer={{
          links: [
            { href: "/", children: t("FAQ") },
            { href: "/", children: t("TermsAndConditions") },
            { href: "/", children: t("PrivacyPolicy") },
          ],
          logo: (
            <p className="text-white">
              {t("Copyright")} {new Date().getFullYear()}
            </p>
          ),
          languageSwitcherProps: {
            textColor: "white",
            items: [
              { label: "English", value: "en" },
              { label: "العربية", value: "ar" },
            ],
            handleDirection: (value: any) =>
              LanguageService.changeLanguage(value as "ar" | "en"),
            suffixLanguageIcon: (
              <CaretDownOutlined className="text-primary-active" />
            ),
            bordered: false,
          },
        }}
      >
        {/* This fragment is because children marked as required in this component */}
        {/* TODO: Fix in design system */}
        <></>
      </SignupPage>
    </div>
  );
};

export default Signup;
