import { Spin } from "antd";
import React, { Dispatch, SetStateAction } from "react";
// import Card from "src/TempPages/OffersPageTemp/CardOffersPage";
// import Pagenation from "src/TempPages/OffersPageTemp/PagenationOffersPage";

interface Props {
  filters: {
    categoryId: number | null;
    pageNumber: number;
    pageSize: number;
  };
  handleFilterChange: Dispatch<
    SetStateAction<{
      categoryId: number | null;
      pageNumber: number;
      pageSize: number;
    }>
  >;
  handler: {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  };
  data: any;
  Pagenation: (props: any) => JSX.Element | null;
  Card: (props: any) => JSX.Element | null;

}

export default function Listing({
  filters,
  handleFilterChange,
  data,
  handler,
  Pagenation,
  Card
}: Props) {
  return (
    <>
      <div className=" flex justify-center 4xl:justify-start items-center">
        {(handler?.isLoading || handler?.isFetching) && data?.length === 0 ? (
          <Spin size="large" />
        ) : data?.Items?.length !== 0 ? (
          <div className="xl:grid-cols-4 4xl:grid-cols-6 gap-x-2 md:gap-x-4 grid grid-cols-2 md:grid-cols-3 gap-y-4 lg:gap-6 overflow-hidden p-1 w-full">
            {data?.Items?.map((item: any) => <Card item={item} />)}
          </div>
        ) : null}
      </div>
      <Pagenation
        filters={filters}
        handleFilterChange={handleFilterChange}
        totalItems={data?.Paginator?.TotalItems}
      />
    </>
  );
}
