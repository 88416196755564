import { Button, Card as DSCard } from "@dsquares/react-component-library";
import { useState } from "react";
import MerchantFlow from "src/components/RedemptionModal/MerchantFlow";
import dayjs from "dayjs";
import { useGetWalletDataQuery } from "src/store";

export default function Card({ item }: { item: any }) {
  const [isMerchantFlowModalOpen, setIsMerchantFLowModalOpen] = useState(false);
  const { data: walletData } = useGetWalletDataQuery(undefined);

  return (
    <>
      <DSCard>
        {item.IsTop && <DSCard.Chip position="topRight">Top Deal</DSCard.Chip>}
        <DSCard.Header>
          <img src={item.Image} alt="Placeholder" />
        </DSCard.Header>
        <DSCard.Title>{item.Title}</DSCard.Title>
        <DSCard.Description className="w-64 text-ellipsis text-nowrap overflow-hidden">
          {item.Description}
        </DSCard.Description>
        <DSCard.Footer>
          <Button onClick={() => {
            setIsMerchantFLowModalOpen(true);
          }}>Redeem</Button>
        </DSCard.Footer>
      </DSCard>
      <MerchantFlow
        isOpen={isMerchantFlowModalOpen}
        totalPoints={walletData?.data.totalPoints || 0}
        pointsExpirationDate={dayjs(walletData?.data.expiryDate).format("DD/MM/YYYY")}
        onModalClose={() => {
          setIsMerchantFLowModalOpen(false);
        }}
      />
    </>
  );
}