import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoute from "src/router";
import { ToastProvider } from "src/context/ToastContext";
import { LanguageService } from "src/services/Language";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";
import { hexToRgb } from "src/utility/utility";
import { ThemeProvider } from "@dsquares/react-component-library";
import { withAppConfig } from "src/HOC/withAppConfig";
import { useZoomLevel } from "src/context/ZoomLevelContext";

function App() {
  const { getRelativePX } = useZoomLevel();
  const appConfig = useSelector(selectAppConfig);
  useEffect(() => {
    LanguageService.changeDocumentLanguageAttributes();
  }, []);

  useEffect(() => {
    const colors = appConfig.colors;
    const styleHTML = document.createElement("style");

    styleHTML.innerHTML = `
    :root {
      --primary-color: ${hexToRgb(colors.primaryColor)};
      --primary-dark-color: ${hexToRgb(colors.secondaryColor)};
      --dark-gray-color: ${hexToRgb(colors.textColor)};
      --danger-color: ${hexToRgb(colors.dangerColor)};
      --success-color: ${hexToRgb(colors.successColor)};
      --warning-color: ${hexToRgb(colors.whiteColor)};
      --info-color: ${hexToRgb(colors.blackColor)};
    }`;
    document.head.appendChild(styleHTML);
  }, [appConfig.colors]);

  const localVariant = appConfig.variant;
  const localColors = appConfig.colors;

  return (
    <ThemeProvider
      injectIntoHead
      theme={{
        variant: localVariant,
        colors: {
          primaryColor: localColors.primaryColor,
          primaryDarkColor: localColors.secondaryColor,
          darkGrayColor: localColors.textColor,
          dangerColor: localColors.dangerColor,
          successColor: localColors.successColor,
          warningColor: localColors.whiteColor,
          infoColor: localColors.blackColor,
        },
        sizes: {
          large: getRelativePX(54),
          middle: getRelativePX(36),
          small: getRelativePX(22),
        },
      }}
    >
      <ToastProvider>
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default withAppConfig(App);
