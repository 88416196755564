import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Fragment } from "react";
import Layout from "src/layout";
import Signup from "src/pages/Authentication/Signup/Signup";
import Login from "src/pages/Authentication/Login/Login";
import OTP from "src/pages/Authentication/OTP/OTP";
import ForgetPassword from "src/pages/Authentication/ForgetPassword/ForgetPassword";
import ResetPassword from "src/pages/Authentication/ResetPassword/ResetPassword";
import DetailsPage from "src/pages/DetailsPage/DetailsPage";
import DealsPage from "src/pages/DealsPage/DealsPage";
import { DealRoutes } from "src/models/RoutesModel";
import HomePage from "src/pages/HomePage/HomePage";
import DSHistoryPage from "src/pages/HistoryPage/HistoryPage";
import StaticPages from "src/pages/StaticPages/StaticPages";
import OffersPage from "src/pages/OffersPage/OffersPage";
import VouchersPage from "src/pages/VouchersPage/VouchersPage";
import PartnersPage from "src/pages/PartnersPage/PartnersPage";
import LoginPage from "src/pages/LoginPage/LoginPage";

export default function AppRoute() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/offers"
          element={
            <ProtectedRoute>
              <OffersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vouchers"
          element={
            <ProtectedRoute>
              <VouchersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partners"
          element={
            <ProtectedRoute>
              <PartnersPage />
            </ProtectedRoute>
          }
        />
        {/* {DealRoutes.length &&
          DealRoutes.map((route) => (
            <Fragment key={route.path}>
              <Route
                path={route.path}
                element={
                  <ProtectedRoute>
                    <DealsPage pathname={route?.path} sectionId={route?.id} />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`${route.path}/:id`}
                element={
                  <ProtectedRoute>
                    <DetailsPage parentPath={route.path} />
                  </ProtectedRoute>
                }
              />
            </Fragment>
          ))} */}
        <Route
          path="/history"
          element={
            <ProtectedRoute>
              <DSHistoryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <StaticPages type="faq" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <ProtectedRoute>
              <StaticPages type="terms" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacy"
          element={
            <ProtectedRoute>
              <StaticPages type="privacy" />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/signup"
        element={
          <AuthRoute>
            <Signup />
          </AuthRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        }
      />
      <Route
        path="/otp"
        element={
          <AuthRoute>
            <OTP />
          </AuthRoute>
        }
      />
      <Route
        path="/forget-password"
        element={
          <AuthRoute>
            <ForgetPassword />
          </AuthRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthRoute>
            <ResetPassword />
          </AuthRoute>
        }
      />
    </Routes>
  );
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const token = localStorage.getItem("PORTAL_TOKEN");

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const token = localStorage.getItem("PORTAL_TOKEN");

  if (token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};
