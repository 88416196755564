import { Card as DSCard } from "@dsquares/react-component-library";
import { CardSlider } from "@dsquares/react-component-library";

import { useRef, useState } from "react";
import { CarouselRef } from "antd/es/carousel";
import useDeals from "src/hooks/useDeals";

export default function DealsSections() {
  const [pageNumber, setPageNumber] = useState(1);
  const ref = useRef<CarouselRef>(null);

  const pathname = "Offers";
  const sectionId = 11;

  const DEALS = useDeals(pageNumber, pathname, sectionId);
  const offersData = DEALS?.offerData?.Items || [];

  const transformedOffersData = offersData?.map((item) => ({
    id: item.Id, 
    ...item,
  }));

  const itemTemplate = (item: { Id: number; Merchant: string; MerchantImage: string }) => (
    <DSCard className="m-3 shadow-lg">
      <DSCard.Header>
        <img src={item.MerchantImage} alt={item.Merchant} />
      </DSCard.Header>
      <DSCard.Title className="text-center">{item.Merchant}</DSCard.Title>
    </DSCard>
  );

  return (
    <>
      <CardSlider>
        <div className="flex items-center justify-between">
          <CardSlider.Title comp="h1">Offers</CardSlider.Title>
        </div>
        <CardSlider.Slider
          sliderSettings={{ arrows: false, slidesPerRow: 4, dots: true }}
          swiperRef={ref}
          className=" mt-8 rounded-2xl p-8  [&_.slick-dots_button]:bg-blue-600"
          items={transformedOffersData}
          itemTemplate={itemTemplate}
        />
      </CardSlider>
    </>
  );
}
