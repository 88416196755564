import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Config from "../../config.json";
import { message as messageToast } from "antd";
import { ApiResponse, getApiErrorMessage } from "../utils";
import { WarningIcon } from "src/assets/icons/WarningIcon";
import { CheckSuccessIcon } from "src/assets/icons/CheckSuccessIcon";
import config from "../../app.config.json";
import { AppDetails } from "src/models/AppConfigModel";

messageToast.config({
  maxCount: 3,
  duration: 3,
});

const baseUrl = Config.elMAGICO_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
});

const baseQueryWithNotification: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    !!extraOptions &&
    "notifyOnFailure" in extraOptions &&
    extraOptions.notifyOnFailure
  ) {
    const errorMessage = getApiErrorMessage(result.error);
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((error) => {
        messageToast.open({
          type: "warning",
          content: error[0],
          className: "custom-warning-message",
          icon: <WarningIcon />,
        });
      });
    } else {
      messageToast.open({
        type: "warning",
        content: errorMessage,
        className: "custom-warning-message",
        icon: <WarningIcon />,
      });
    }
  } else if (
    result.data &&
    !!extraOptions &&
    "notifyOnSuccess" in extraOptions &&
    extraOptions.notifyOnSuccess
  ) {
    const response = result.data as ApiResponse;
    messageToast.open({
      type: "success",
      content: response.message || "Process completed successfully",
      className: "custom-success-message",
      icon: <CheckSuccessIcon />,
    });
  }

  return result;
};

const BaseApi = createApi({
  reducerPath: "BaseApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    AppConfig: builder.query<AppDetails, undefined>({
      query: () => ({
        url: `/api/Apps/GetAppById?id=${config.id}&appType=web`,
        method: "GET",
      }),
      transformResponse: (response: AppDetails) => {
        return response;
      },
    }),
    AppLocales: builder.query({
      query: () => ({
        url: `/api/lang/getKeys?id=${config.id}&type=web`,
        method: "GET",
      }),
      transformResponse: (
        response: {
          language: string;
          keys: {
            id: number;
            key: string;
            value: string;
          }[];
        }[]
      ) => {
        return response;
      },
    }),
  }),
});
export const { useLazyAppConfigQuery, useLazyAppLocalesQuery } = BaseApi;
export default BaseApi;
