import Markdown from "react-markdown";
import "./StaticPages.css";
import { useLazyAppConfigQuery } from "src/store/apis/BaseApi";
import { useEffect, useState } from "react";
import * as DOMPurify from "dompurify";

const StaticPages = ({ type }: { type: "faq" | "terms" | "privacy" }) => {
  const [getAppConfig] = useLazyAppConfigQuery();
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [markdownData, setMarkdownData] = useState<{
    faq: string;
    terms: string;
    privacy: string;
  }>({ faq: "", terms: "", privacy: "" });

  const setupAppConfig = async () => {
    const res = await getAppConfig(undefined).unwrap();
    setMarkdownData({
      faq: res.fqs,
      terms: res.terms_condtion,
      privacy: res.privacy_policy,
    });
  };

  useEffect(() => {
    setupAppConfig();
  }, []);

  useEffect(() => {
    setHtmlContent(markdownData[type]);
  }, [type, markdownData]);

  return (
    <div
      className="static-page"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(htmlContent, {
          USE_PROFILES: { html: true },
        }),
      }}
    >
      {/* {markdown} */}
    </div>
  );
};

export default StaticPages;
